import { constantRoutes } from "@/router";

import Layout from "@/layout";

//动态路由懒加载配置会根据webpack的版本不同而变化,具体可查看以下方案
//https://blog.csdn.net/hunt_er/article/details/110661577
function loadView(view) {
  return (resolve) => require([`@/views${view}`], resolve);
}

export function filterAsyncRoutes(server_routes, childflag) {
  const res = [];

  server_routes.forEach((tmp) => {
    const cname = tmp.url.substr(tmp.url.lastIndexOf("/") + 1, tmp.length);
    const routerjson = {
      path: tmp.url,
      name: cname,
      meta: {
        title: tmp.moduleName,
        icon: tmp.icon,
      },
    };
    if (childflag) {
      routerjson.component = loadView(tmp.url);
      routerjson.path = cname;
    } else {
      routerjson.component = Layout;
    }
    if (tmp.children && tmp.children.length > 0) {
      routerjson.children = filterAsyncRoutes(tmp.children, true);
    }
    res.push(routerjson);
  });
  return res;
}

const state = {
  routes: [],
};

const mutations = {
  SET_ROUTES: (state) => {
    state.routes = constantRoutes;
  },
};

const actions = {
  generateRoutes({ commit }) {
    return new Promise((resolve) => {
      commit("SET_ROUTES");
      resolve(constantRoutes);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
