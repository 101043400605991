<template>
  <div class="hl-layout-wrapper">
    <div class="hl-container">
      <div class="hl-content">
        <AppNavBar v-if="pageBackFlag"
                   :BackTitle="pageBackTitle"></AppNavBar>
        <AppMain></AppMain>
        <FootRouter v-if="!pageBackFlag"
                    :tabbarItem="bottomRoutes"></FootRouter>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { AppMain, FootRouter, AppNavBar } from './components'
export default {
  name: 'Layout',
  components: {
    AppMain, FootRouter, AppNavBar
  },
  computed: {
    ...mapState({
      //返回条的标题
      pageBackTitle: state => state.app.routerConfig.pageBackTitle,
      //空置返回条是否先显示
      pageBackFlag: state => state.app.routerConfig.pageBackFlag,
      //获取路由中type为0的路由数据，该数据即为底部导航
      bottomRoutes (state) {
        const arrGroup = this.$arrayGroup(state.permission.routes, "type")
        const re = this.$getTypeName(arrGroup, "type", "0", {})
        if (re && re.data) {
          return re.data
        }
        else {
          return []
        }
      },
    })
  },
  methods: {

  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
