import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";

Vue.use(Router);
// title:页面标题
// type:路由权限，0代表移动端底部导航，1代表手机端首页的menu菜单
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "*",
    component: () => import("@/views/error-page/404"),
    hidden: true,
    meta: {
      title: "401未找到",
    },
  },
  {
    path: "/system",
    component: Layout,
    children: [
      {
        path: "setting",
        component: () => import("@/views/system/components/setting"),
        name: "setting",
        meta: {
          title: "设置",
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/home/appMenu",
  },
  //以上为静态路由，一下为动态路由，后期由后台统一配置
  {
    path: "/home",
    component: Layout,
    title: "首页",
    type: "1",
    url: "/home/appMenu",
    icon: "home-o",
    children: [
      {
        path: "appMenu",
        component: () => import("@/views/home/appMenu"),
        name: "appMenu",
      },
    ],
  },
  {
    path: "/home",
    component: Layout,
    title: "我的",
    type: "1",
    url: "/home/my",
    icon: "user-o",
    children: [
      {
        path: "my",
        component: () => import("@/views/home/my"),
        name: "my",
      },
    ],
  },
  {
    path: "/system",
    component: Layout,
    title: "测试1",
    type: "1",
    url: "/system/personal",
    icon: require("@/assets/logo.png"),
    children: [
      {
        path: "personal",
        component: () => import("@/views/system/personal"),
        name: "personal",
      },
    ],
  },
  {
    path: "/system",
    component: Layout,
    title: "测试2",
    type: "1",
    url: "/system/personal",
    icon: require("@/assets/logo.png"),
    children: [
      {
        path: "personal",
        component: () => import("@/views/system/personal"),
        name: "personal",
      },
    ],
  },
  {
    path: "/system",
    component: Layout,
    title: "测试3",
    type: "1",
    url: "/system/personal",
    icon: require("@/assets/logo.png"),
    children: [
      {
        path: "personal",
        component: () => import("@/views/system/personal"),
        name: "personal",
      },
    ],
  },
];
const createRouter = () =>
  new Router({
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  });
const router = createRouter();
export default router;
