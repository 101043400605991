<template>
  <div>
    <van-nav-bar :title="BackTitle"
                 left-text="返回"
                 left-arrow
                 @click-left="onClickLeft" />
  </div>
</template>

<script> 
export default {
  name: 'AppNavBar',
  components: {},
  props: {
    BackTitle: {
      type: String,
      require: false,
      default: ''
    },
  },
  data () {
    return {
      active: 0,
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {

  },
  destroyed () {
  },
  methods: {
    onClickLeft () {
      this.$jumpGo(-1)
    }
  }
}
</script>

<style lang="scss">
</style>
