import axios from 'axios'
import {
  Dialog,
  Notify
} from 'vant'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, 
  timeout: 10000
})



service.interceptors.request.use(
  config => {
    if (store.getters.user.token) {
      config.headers['token'] = store.getters.user.token
    } else {
      config.headers['token'] = localStorage.getItem("mobiletoken")
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 401) {
      Dialog.confirm({
          title: '友情提示',
          message: '登录超时,请点击确定重新登录',
        })
        .then(() => {
          store.dispatch('user/loginout').then(() => {
            location.reload()
          })
        })
        .catch(() => {});
    }
    return res
  },
  error => {
    console.log('err' + error) // for debug
    Notify({
      type: 'danger',
      message: "服务不通:"+error.message,
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service