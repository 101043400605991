const state = {
  routerConfig: {
    pageBackFlag: true,
    pageBackTitle: ''
  },
}
const mutations = {
  SHOW_BACK: (state, parm) => {
    state.routerConfig.pageBackFlag = parm
  },
  SET_BARTITLE: (state, parm) => {
    state.routerConfig.pageBackTitle = parm
  },
}
const actions = {
  showBackBar({
    commit
  }, parm) {
    commit('SHOW_BACK', parm)
  },
  setBackBarTitle({
    commit
  }, parm) {
    commit('SET_BARTITLE', parm)
  },
  getBackFlag({}) {
    return new Promise(resolve => {
      resolve(state.routerConfig.pageBackFlag)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}