<template>
  <div class="footer-container">
    <div class="footer-view">
      <div :style="show1"
           @click="main"
           class="footer-item">
        <img style="width: 20px"
             src="../../assets/icon/icon_main2.png">
        首页
      </div>
      <div :style="show2"
           @click="my"
           class="footer-item">
        <img style="width: 20px"
             src="../../assets/icon/icon_my2.png">
        我的
      </div>
    </div>

    <div :style="show2"
         class="footer-chooseitem">
      <img style="width: 50px"
           src="../../assets/icon/icon_main1.png">
      首页
    </div>
    <div :style="show1"
         style="left: 50%"
         class="footer-chooseitem">
      <img style="width: 50px"
           src="../../assets/icon/icon_my1.png">
      我的
    </div>
    
    <div class="Bq-text">版权所有：潍坊信诚铸安安全管理服务有限公司</div>
  </div>
</template>

<script>
import Utils from '../../utils/util.js';
export default {
  name: 'FootRouter',
  components: {},
  props: {
    tabbarItem: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      show1: 'visibility: hidden;',
      show2: ''
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    if (this.$route.query.title === '首页') {
      this.show1 = 'visibility: hidden;';
      this.show2 = '';
    } else if (this.$route.query.title === '我的') {
      this.show1 = '';
      this.show2 = 'visibility: hidden;';
    }

    var that = this;
    Utils.$on('my', function () {
      that.my();
    });
  },
  destroyed() {},
  methods: {
    main() {
      this.show1 = 'visibility: hidden;';
      this.show2 = '';
      this.$jumpPage('/home/appMenu', { title: '首页' });
    },
    my() {
      this.show1 = '';
      this.show2 = 'visibility: hidden;';
      this.$jumpPage('/home/my', { title: '我的' });
    }
  }
};
</script>

<style lang="scss">
.footer-container {
  width: 100%;
  height: 75px;
  position: relative;
}

.footer-view {
  width: 100%;
  background: white;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  text-align: center;
  color: #a8a8a8;
  font-size: 12px;
}

.footer-chooseitem {
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  text-align: center;
  color: #333333;
  font-size: 13px;
}

.Bq-text{
    height: 25px;
    text-align: center ;
    font-size: 0.75rem;
}
</style>
