
//潍坊配置
const state = {
  systemTitle: "潍坊安全生产考试服务平台",
  wxConfig: {
    //微信公众号的appid
    appid: "wxa4d18e90ddc5de0f",
    // 微信登录授权、微信登录授权的路径配置一样
    urlVerify: "simu.wfxcza.com",
    //系统webUrl
    webUrl: "simu.wfxcza.com",
  }
}

//高密配置
// const state = {
//   systemTitle: "高密市秉心安全生产培训中心",
//   wxConfig: {
//     appid: "wx1b5377d2b79cdedd",
//     urlVerify: "gaomi.hlgoing.com",
//     webUrl: "gaomi.hlgoing.com",
//   }
// }

export default {
  namespaced: true,
  state
}