import request from "@/utils/request";

// 微信登录
export function getUserInfo(data) {
  return request({
    url: "/api/wxSns/getUserInfo.json",
    method: "get",
    params: data,
  });
}

// 获取所有题库
export function getAllQuesconect(data) {
  return request({
    url: "/api/quesconect/getAllList.json",
    method: "get",
    params: data,
  });
}

// 获取模拟试卷
export function getSimulatePaper(data) {
  return request({
    url: "/api/ques/getSimulatePaper.json",
    method: "post",
    data,
  });
}

// 验证支付状态
export function checkPay(data) {
  return request({
    url: "/api/quesconect/checkPay.json",
    method: "post",
    data,
  });
}

// 更新用户信息
export function trainstudents(data) {
  return request({
    url: "/api/trainstudents/saveData.json",
    method: "post",
    data,
  });
}

// 交卷
export function SubmitSocre(data) {
  return request({
    url: "/api/trainsubmithistory/SubmitSocre.json",
    method: "post",
    data,
  });
}

// 交卷 错题
export function SaveCuoTi(data) {
  return request({
    url: "/api/traincuotibiao/SaveCuoTi.json",
    method: "post",
    data,
  });
}
// 练题分析走势图
export function getChart(data) {
  return request({
    url: " /api/trainsubmithistory/getDataList.json",
    method: "post",
    data,
  });
}
// 自定义考场列表
export function getSelfDataList(data) {
  return request({
    url: "/api/trainplan/getDataList.json",
    method: "post",
    data,
  });
}
// 自定义考场保存
export function saveSelfData(data) {
  return request({
    url: "/api/trainplan/saveData.json",
    method: "post",
    data,
  });
}

// 练习分析
export function getStudent(data) {
  return request({
    url: "/api/trainstudentsanalysis/getDataInfo.json",
    method: "get",
    params: data,
  });
}

// 保存练题次数
export function saveStudent(data) {
  return request({
    url: "/api/trainstudentsanalysis/saveData.json",
    method: "post",
    data,
  });
}

// 错题集
export function getCuoTiList(data) {
  return request({
    url: "/api/traincuotibiao/getCuoTiList.json",
    method: "post",
    data,
  });
}

// 错题详情
export function getCuoTiDetail(data) {
  return request({
    url: "/api/traincuotibiao/getDetail.json",
    method: "post",
    data,
  });
}

// 获取字典
export function getSysDictionary(data) {
  return request({
    url: "/api/sysDictionary/getAllList.json",
    method: "post",
    data,
  });
}

// 获取班级
export function getTrain(data) {
  return request({
    url: "/api/train/getAllList.json",
    method: "post",
    data,
  });
}

export function getUserInfoForId(data) {
  return request({
    url: "/api/trainstudents/getUserInfo.json",
    method: "post",
    data,
  });
}
export function getTrainList(data) {
  return request({
    url: "/api/train/getAllList.json",
    method: "post",
    data,
  });
}
export function getAnalysisZx(data) {
  return request({
    url: "/api/trainsubmithistory/GetAnalysisZx.json",
    method: "post",
    data,
  });
}

// 平均成绩
export function getAnalysis(data) {
  return request({
    url: "/api/trainsubmithistory/GetAnalysis.json",
    method: "post",
    data,
  });
}
