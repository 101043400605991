import * as apiInterface from "@/api/apiInterface.js";

const actions = {
  getUserInfo({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getUserInfo(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAllQuesconect({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getAllQuesconect(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSimulatePaper({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getSimulatePaper(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkPay({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .checkPay(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  trainstudents({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .trainstudents(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  SubmitSocre({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .SubmitSocre(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  SaveCuoTi({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .SaveCuoTi(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getChart({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getChart(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSelfDataList({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getSelfDataList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveSelfData({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .saveSelfData(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getStudent({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getStudent(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveStudent({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .saveStudent(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCuoTiList({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getCuoTiList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCuoTiDetail({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getCuoTiDetail(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getSysDictionary({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getSysDictionary(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getTrain({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getTrain(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUserInfoForId({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getUserInfoForId(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getTrainList({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getTrainList(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAnalysisZx({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getAnalysisZx(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAnalysis({}, parm) {
    return new Promise((resolve, reject) => {
      apiInterface
        .getAnalysis(parm)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  actions,
};
