import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './permission'
import '@/styles/default.scss'
import Vant from 'vant';
import 'vant/lib/index.css';
import comFun from './comfun'
import * as echarts from 'echarts'
// 主题定制
// import 'vant/lib/index.less';

document.title = store.getters.appconfig.systemTitle;

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;
Vue.prototype.$comFun = comFun
Vue.use(comFun)
Vue.use(Vant);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");